// src/CoordinatesPage.js
import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const CoordinatesPage = () => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [displayCoordinates, setDisplayCoordinates] = useState(null);

  const handleSetCoordinates = async () => {
    try {
      await fetch(`${API_URL}/set-coordinates`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ latitude, longitude }),
      });
      fetchCoordinates();
    } catch (error) {
      console.error('Error al guardar coordenadas:', error);
    }
  };

  const fetchCoordinates = async () => {
    try {
      const response = await fetch(`${API_URL}/get-coordinates`);
      const data = await response.json();
      setDisplayCoordinates(data);
    } catch (error) {
      console.error('Error al obtener coordenadas:', error);
    }
  };

  useEffect(() => {
    fetchCoordinates();
  }, []);

  return (
    <div>
        <h1>Datos del GPS</h1>
        {displayCoordinates ? (
            <div>
                <p>Latitud: {displayCoordinates.latitude}</p>
                <p>Longitud: {displayCoordinates.longitude}</p>
            </div>
        ) : (
            <p>Cargando datos...</p>
        )}
    </div>
    
  );
};

export default CoordinatesPage;